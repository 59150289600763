import './App.css';
import Splash from './Splash';
import Search from './Search'
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import React from 'react';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: '/splash'
        }
    }

    render() {
        return (
            <Router>
                <Redirect to={this.state.redirect} />
                <Switch>
                    <Route path="/search/">
                        <Search />
                    </Route>
                    <Route path="/splash/">
                        <Splash />
                    </Route>
                </Switch>
            </Router>
        );
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({redirect: '/search'})
        }, 3000);
    }

}

export default App;

import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";

function Search() {
    return (
        <Router>
            <div className="container-fluid">
                <header className="App-header ">
                    <nav className="navbar navbar-light row">
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a href="#">Link</a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <img src="https://utg.dk/wp-content/uploads/2020/04/UTG_LOGO_2020-14-e1595933939992.png"
                         alt="logo"/>
                </header>


            </div>
            <main>
            </main>
        </Router>
    )
};

export default Search;

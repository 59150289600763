import './Splash.css';

function Splash() {
    return (
        <div className="container-fluid">
            <div className="viewport">
                <img
                    src="https://utg.dk/wp-content/uploads/2020/04/UTG_LOGO_2020-14-e1595933939992.png"
                    className="App-logo"
                    alt="logo"/>
            </div>
        </div>
    )
};

export default Splash;
